<template>
	<div>
		<div class="d-flex align-items-center">
			<slot :open="open"></slot>
			<div>
				<span class="text-success cursor-pointer ml-5" @click="open()" v-if="employees.length">View/Edit</span>
			</div>
		</div>
		<b-modal id="employee-selection-modal"
			:title="controlTitle + ` (${applicableEmployees.length})`"
			no-close-on-backdrop
			hide-header-close
			no-close-on-esc
			>
			<div class="p-2">
				<div v-if="applicableEmployees.length">
					<div class="d-flex justify-content-between">
						<b-form-checkbox
							v-model="allSelected"
							:indeterminate="indeterminate"
							aria-describedby="flavours"
							aria-controls="flavours"
							@change="toggleAll"
							>
							{{ allSelected ? 'Un-select All' : 'Select All' }}
						</b-form-checkbox>
						<span class="text-danger cursor-pointer"
							@click="removeSelected"
							v-if="selectedEmployeeIds.length"><small>Remove selected ({{this.selectedEmployeeIds.length}})</small></span>
					</div>
					<b-form-checkbox-group
						id="flavors"
						v-model="selectedEmployeeIds">
						<div class="staff-wrapper">
							<div class="search">
								<div class="form-group">
									<input type="text" class="form-control" placeholder="Search staff" v-model="employeeSearch">
								</div>
							</div>
							<div class="list">
								<b-form-checkbox
									v-for="e in renderedEmployeeList"
									:value="e.id"
									:key="e.id">
									<span class="text-capitalize">{{ e.fname }} {{ e.lname }}</span>
								</b-form-checkbox>
							</div>
						</div>
					</b-form-checkbox-group>
				</div>
				<div v-else>
					<p class="text-center">Staff added will be rendered here</p>
				</div>
				<div class="row align-items-center mt-4">
					<div class="col-10">
						<div class="form-group">
							<label class="mb-0">Add to list</label>
							<multiselect
								v-model="newApplicableEmployees"
								id="ajax"
								:custom-label="fullName"
								track-by="id"
								placeholder="Type to search"
								:options="staffList"
								:multiple="true"
								:searchable="true"
								:loading="isLoading"
								:internal-search="false"
								:clear-on-select="false"
								:close-on-select="false"
								:preserve-search="true"
								:options-limit="300"
								:show-no-results="false"
								:hide-selected="true"
								@search-change="asyncFindEmployee"
								>
								<template slot="clear" slot-scope="props">
									<div
										class="multiselect__clear"
										v-if="newApplicableEmployees.length"
										@mousedown.prevent.stop="clearAll(props.search)"
										></div>
								</template>
								<span
									slot="noResult"
									>
									Oops! No elements found. Consider changing the search query.
								</span>
							</multiselect>
						</div>
					</div>
					<div class="col-2">
						<button class="btn btn-outline-primary" @click="addNewEmployees">Add</button>
					</div>
				</div>

			</div>
			<template #modal-footer>
				<button
					@click="close"
					type="button"
					:disabled="processing"
					class="btn btn-secondary">Cancel</button>
				<button
					@click="saveChanges"
					type="button"
					:disabled="processing"
					class="btn btn-primary">
					{{ processing ? 'Processing...' : 'Save Changes' }}
				</button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  components: { Multiselect },
  name: 'CreditLineEmployeeSelector',
  props: {
    employees: {
      type: Array,
      required: true
    },
    selectorOpen: {
      type: Boolean,
      required: true
    },
    controlTitle: {
      type: String
    },
    companyId: {
      type: [Number, String]
    },
    handleUpdate: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      applicableEmployees: [],
      selectedEmployeeIds: [],
      employeeSearch: '',
      allSelected: false,
      indeterminate: false,
      newApplicableEmployees: [],
      staffList: [],
      isLoading: false,
      processing: false
    }
  },
  watch: {
    selectorOpen: {
      handler: function (value) {
        if (value) {
          this.applicableEmployees = this.employees
          this.openModal()
        } else {
          this.selectedEmployeeIds = []
          this.applicableEmployees = []
          this.closeModal()
        }
      }
    },
    selectedEmployeeIds (newValue) {
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.applicableEmployees.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    }
  },
  computed: {
    renderedEmployeeList () {
      const searchString = this.employeeSearch.toLowerCase()
      return this.employeeSearch
        ? this.applicableEmployees.filter((obj) => {
          return (obj.fname && obj.fname.toLowerCase().includes(searchString)) || (obj.lname && obj.lname.toLowerCase().includes(searchString))
        })
        : this.applicableEmployees
    }
  },
  methods: {
    async saveChanges () {
      const applicableUserIds = this.applicableEmployees.map((_) => _.id)
      if (this.handleUpdate) {
        try {
          this.processing = true
          await this.axios.patch(`/v1/credit-systems/${parseInt(this.companyId)}/applicable-employees`, {
            applicable_employee_value: applicableUserIds
          })
          this.$emit('changes-saved', this.applicableEmployees)
          this.close()
          await this.$swal({
            icon: 'success',
            title: 'Updated successfully',
            text: 'Employees list updated successfully'
          })
        } catch (error) {
        } finally {
          this.processing = false
        }
      } else {
        this.$emit('changes-saved', this.applicableEmployees)
        this.close()
      }
    },
    open () {
      this.$emit('show')
    },
    close () {
      this.$emit('close')
    },
    closeModal () {
      this.$bvModal.hide('employee-selection-modal')
    },
    openModal () {
      this.$bvModal.show('employee-selection-modal')
    },
    toggleAll (checked) {
      this.selectedEmployeeIds = checked ? this.applicableEmployees.map((obj) => obj.id) : []
    },
    fullName ({ fname, lname }) {
      return `${fname} ${lname}`
    },
    asyncFindEmployee (query) {
      if (query) {
        this.isLoading = true
        this.axios
          .get(`/v1/corporates/${this.companyId}/staff`, { params: { query } })
          .then((response) => {
            this.staffList = response.data.data
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
            this.staffList = []
          })
      } else {
        this.staffList = []
      }
    },
    addNewEmployees () {
      const empMap = this.applicableEmployees.reduce((res, item) => {
        res.set(item.id, true)
        return res
      }, new Map())
      const staffToAdd = []
      this.newApplicableEmployees.forEach((employee) => {
        if (!empMap.has(employee.id)) {
          staffToAdd.push(employee)
        }
      })
      this.applicableEmployees.push(...staffToAdd)
      this.newApplicableEmployees = []
    },
    clearAll () {
      this.newApplicableEmployees = []
    },
    removeSelected () {
      this.applicableEmployees = this.applicableEmployees.filter((item) => !this.selectedEmployeeIds.includes(item.id))
      this.selectedEmployeeIds = []
    }
  }
}
</script>

  <style lang="scss" scoped>
  .staff-wrapper {
    min-height: 5rem;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    border-radius: 8px;
    border: 1px solid #e9edf2;
    position: relative;

    .search{
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      right: 0.5rem;
      width: calc(100% - 1rem);
    }
    .list {
      margin-top: 3rem;
      overflow: auto;
      display: flex;
      flex-direction: column;
      max-height: 10rem;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }
  </style>
