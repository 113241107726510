<template>
	<div>
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
		<div v-else>
			<div v-if="needCreditSystem">
				<div class="center">
					<div class="pt-5 text-center">
						<p>
							This company currently doesn't have any employee credit line
							system. Create one below
						</p>

						<div class="mt-3">
							<router-link class="btn btn-primary" :to="{
								name: 'CreateCompanyCreditLine',
								params: { companyId: companyId },
							}">Create Employee Credit System</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="card" v-if="currentCreditSystem">
				<div class="card-body">
					<div class="row">
						<div class="col-12 mb-4">
							<div class="d-flex flex-row">
								<h5 class="flex-grow-1">Credit Line</h5>
								<router-link class="btn btn-outline-dark btn-sm mr-2" :to="{
									name: 'UpdateCompanyCreditLine',
									params: { companyId: companyId },
								}">
									Edit credit system
								</router-link>

								<!-- Example split danger button -->
								<div class="btn-group">
									<button class="btn btn-primary btn-sm" @click.prevent="executeCreditSystem">
										Apply Credit System
									</button>
									<button type="button" class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-split"
										data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span class="sr-only">Toggle Dropdown</span>
									</button>
									<div class="dropdown-menu">
										<router-link class="dropdown-item" :to="{
											name: 'TopUpSelectStaff',
											params: { companyId: this.companyId },
										}">Apply to select staff</router-link>
										<a href="#" class="dropdown-item" @click.prevent="applyCreditSystemLater">Apply credit system
											later</a>
									</div>
								</div>
							</div>
						</div>

						<div class="col-12">
							<table class="table">
								<tbody>
									<tr>
										<td>Status</td>

										<td>
											<span class="badge" :class="currentCreditSystem.is_active == 1
												? 'badge-info'
												: 'badge-danger'
											">{{
												currentCreditSystem.is_active == 1
													? 'active'
													: 'inactive'
											}}</span>
										</td>
									</tr>
									<tr>
										<td>Amount</td>

										<td>
											<span>{{ currentCreditSystem.amount }}</span>
										</td>
									</tr>

									<tr>
										<td>Applicable Employees</td>

										<td>
											<span>{{
												getApplicableEmployeeNarration(
													currentCreditSystem.applicable_employee_kind
												)
											}}</span>
										</td>
									</tr>

									<tr v-if="currentCreditSystem.applicable_employee_kind != 'all'">
										<td>Selected Employees</td>

										<td>
											<credit-line-employee-selector @close="closeStaffSelector()" @show="openStaffSelector()"
												@changes-saved="updateData"
												:control-title="currentCreditSystem.applicable_employee_kind === 'all_except' ? 'Employees to exempt' : 'Employees to include'"
												:company-id="companyId" :selector-open="showEmployeeSelector" :employees="applicableEmployee">
												{{ applicableEmployee.length }}
											</credit-line-employee-selector>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<div class="card mt-5" v-if="currentCreditSystem">
				<div class="card-body p-0">
					<v-server-table ref="executionsTable" :columns="columns" :options="options" @loading="tableLoading = true"
						@loaded="tableLoading = false">
						<div slot="beforeTable" class="container">
							<div class="row">
								<div class="col-12 py-3 px-3">
									<div class="d-flex flex-row-reverse">
										<button class="btn btn-sm btn-outline-dark" @click="$refs.executionsTable.refresh()">
											Refresh Executions
										</button>
									</div>
								</div>
							</div>
						</div>

						<span slot="id" slot-scope="props">
							<span>{{ props.index }}</span>
						</span>

						<span slot="applied at" slot-scope="props">
							<span>{{ props.row.completed_at | formatDateTime }}</span>
						</span>

						<div slot="actions" slot-scope="props">
							<div v-if="!props.row.is_completed && props.row.scheduled">
								<span>Scheduled for <strong>{{ props.row.scheduled_for | formatDateTime }}</strong></span>
								<button class="btn btn-sm ml-2 btn-primary" @click.prevent="cancelExecution(props.row.id)">Cancel</button>
							</div>
							<span v-if="props.row.is_completed"> {{ props.row.execution_status }}</span>
						</div>
						<span slot="is_completed" slot-scope="props">
							<span class="badge" :class="props.row.is_completed == 0
								? 'badge-warning'
								: 'badge-success'
							">{{ props.row.is_completed == 1 ? 'YES' : props.row.scheduled ? 'SCHEDULED' : 'PENDING' }}</span>
						</span>
					</v-server-table>
				</div>
			</div>
		</div>
		<apply-credit-system-later :show="showApplyCreditSystemLaterModal" :entity="currentCreditSystem"
			@close="showApplyCreditSystemLaterModal = false; refreshExecutions()"></apply-credit-system-later>
	</div>
</template>

<script>
import CreditLineEmployeeSelector from '@/components/modules/transactions/CreditLineEmployeeSelector'
import ApplyCreditSystemLater from '@/components/modals/ApplyCreditSystemLaterModal'
import moment from 'moment'

export default {
  components: {
    CreditLineEmployeeSelector,
    ApplyCreditSystemLater
  },
  props: {
    companyId: {
      required: true,
      type: [Number, String]
    }
  },
  filters: {
    formatDateTime(val) {

      if (!val) return val

      return moment(val).format('yyyy-MM-DD hh:mm a')
    }
  },
  data() {
    const vm = this
    return {
      showApplyCreditSystemLaterModal: false,
      showEmployeeSelector: false,
      migratingWallet: false,
      processingExecution: false,
      loading: false,
      currentCreditSystem: null,
      needCreditSystem: false,
      error: null,

      tableLoading: false,
      companies: null,
      columns: [
        'id',
        'is_completed',
        'credit_amount',
        'employees_processed',
        'employees_affected',
        'employees_count',
        'applied at',
        'actions'
      ],
      options: {
        filterable: false,
        requestFunction: (data) => {
          return vm.fetchExecutions(data).catch(function (e) {
            // this.dispatch('error', e);

          })
        }
      },
      applicableEmployee: [],
      initialApplicableEmployeeLength: 0,
      selectedEmployees: []
    }
  },
  mounted() {
    this.fetchCompanyCreditSystem(this.companyId)
  },
  methods: {
    applyCreditSystemLater() {
      this.showApplyCreditSystemLaterModal = true
    },
    async fetchExecutions(params) {
      const result = await this.axios.get(
        `/v1/credit-systems/${this.currentCreditSystem.id}/executions`,
        { params }
      )
      const newResult = {
        ...result.data,
        data: result.data.data,
        count: result.data.total
      }
      return {
        data: newResult
      }
    },
    async toggleEmployee(e) {
      const isUserPresent = (element) => element.id === e.id
      const currentIndex = this.applicableEmployee.findIndex(isUserPresent)
      if (currentIndex > -1) {
        this.applicableEmployee.splice(currentIndex, 1)
      }
      const applicableUserIds = this.applicableEmployee.map((_) => _.id)
      try {
        await this.axios.patch(`/v1/credit-systems/${parseInt(this.companyId)}/applicable-employees`, {
          applicable_employee_value: applicableUserIds
        })
      } catch (error) {

      }
    },
    refreshExecutions() {
      this.$refs.executionsTable.refresh()
    },
    async executeCreditSystem() {
      if (this.processingExecution) return

      const result = await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text:
          'Are you sure you want to apply this credit to the applicable staff of this company?',
        showConfirmButton: true,
        showCancelButton: true
      })

      if (result.isConfirmed) {
        this.processingExecution = true
        try {
          if (this.initialApplicableEmployeeLength !== this.applicableEmployee.length) {
            const applicableIds = this.applicableEmployee.map((m) => m.id)
            await this.axios.patch(`/v1/credit-systems/${this.currentCreditSystem.id}`, {
              amount: this.currentCreditSystem.amount,
              applicable_employee_kind: this.currentCreditSystem.applicable_employee_kind,
              applicable_employee_value: applicableIds,
              is_automatic: this.currentCreditSystem.is_automatic ? parseInt(this.currentCreditSystem.is_automatic) : 0
            })
          } else {
            await this.axios.post(
              `/v1/credit-systems/${this.currentCreditSystem.id}/executions`
            )
          }
          this.processingExecution = true
          this.$swal({
            icon: 'success',
            title: 'Scheduled successfully',
            text:
              'Credit System has been scheduled to apply to the eligible staff',
            showCloseButton: true
          })
          this.$refs.executionsTable.refresh()
        } catch (e) {
          this.processingExecution = false
          this.$swal({
            icon: 'error',
            title: 'Failed to apply credit',
            text:
              e.response && e.response.data && e.response.data.message
                ? e.response.data.message
                : e.toString(),
            showCloseButton: true
          })
        }
      }
    },

    async cancelExecution(executionId) {
      if (this.migratingWallet) return
      const result = await this.$swal({
        icon: 'question',
        title: 'Cancel execution',
        text:
          'Are you sure you want to cancel this credit system execution?',
        showConfirmButton: true,
        showCancelButton: true
      })

      if (result.isConfirmed) {
        try {
          this.migratingWallet = true
          await this.axios.delete(
            `/v1/credit-systems-executions/${executionId}`
          )
          this.$swal({
            icon: 'success',
            title: 'Successfully cancelled',
            text: 'Cancelled execution successfully',
            showCloseButton: true
          })
          this.migratingWallet = false
        } catch (e) {
          this.migratingWallet = false
          this.$swal({
            icon: 'error',
            title: 'Failed to cancel execution',
            text:
              e.response && e.response.data && e.response.data.message
                ? e.response.data.message
                : e.toString(),
            showCloseButton: true
          })
        }
      }
      this.refreshExecutions()
    },

    async migrateToCreditSystem() {
      if (this.migratingWallet) return
      const result = await this.$swal({
        icon: 'question',
        title: 'Attempt to migrate all staff members wallet',
        text:
          'Are you sure you want to migrate all staff members of this company\'s wallet to the new credit system?',
        showConfirmButton: true,
        showCancelButton: true
      })

      if (result.isConfirmed) {
        try {
          this.migratingWallet = true
          await this.axios.post(
            `/v1/corporates/${this.companyId}/migrate-wallets`
          )
          this.$swal({
            icon: 'success',
            title: 'Migration scheduled',
            text: 'Wallets for staff has started migrated to credit',
            showCloseButton: true
          })
          this.migratingWallet = false
        } catch (e) {
          this.migratingWallet = false
          this.$swal({
            icon: 'error',
            title: 'Failed to migrate wallets',
            text:
              e.response && e.response.data && e.response.data.message
                ? e.response.data.message
                : e.toString(),
            showCloseButton: true
          })
        }
      }
    },

    getApplicableEmployeeNarration(kind) {
      switch (kind) {
        case 'some':
          return 'Some Employees'

        case 'all_except':
          return 'All Employees Except'
      }
      return 'All Employees'
    },
    async fetchCompany(companyId) {
      if (this.loading) return

      try {
        this.loading = true
        const result = await this.axios.get(`/v1/corporates/${companyId}`)
        this.currentCompany = result.data
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    async fetchCompanyCreditSystem(companyId) {
      if (this.loading) return

      try {
        this.loading = true
        const result = await this.axios.get(
          `/v1/corporates/${companyId}/credit-system`
        )
        this.currentCreditSystem = result.data
        const { applicable_employee } = this.currentCreditSystem
        this.initialApplicableEmployeeLength = applicable_employee.length
        this.applicableEmployee = applicable_employee
        this.loading = false
      } catch (e) {
        this.currentCreditSystem = null
        this.error = e.toString()
        if (e.response && e.response.status == 404) {
          this.needCreditSystem = true
        }
        this.loading = false
      }
    },

    openStaffSelector() {
      this.showEmployeeSelector = true
    },
    closeStaffSelector() {
      this.showEmployeeSelector = false
    },
    updateData(newEmployeeList) {
      this.applicableEmployee = newEmployeeList
    }
  }
}
</script>

<style lang="scss" scoped>
._list {
  cursor: pointer;
  margin-right: 0.25rem;
}

._icon {
  color: red;
}
</style>
