<template>
	<div
		class="modal fade"
		id="applyCreditLineLater"
		tabindex="-1"
		role="dialog"
		aria-labelledby="applyCreditLineLater"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="applyCreditLineLaterTitle">Apply credit line later</h3>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div
						class="alert alert-danger alert-dismissible fade show"
						role="alert"
						v-if="errorMessage"
						>
						{{ errorMessage }}
					</div>
					<form>

						<div class="row">
							<div class="col-12">
								<div class="form-group">
									<label class="form-label">Narration</label>
									<input type="text" v-model="form.narration" placeholder="Narration" class="form-control">
								</div>
								<div class="form-group">
									<label class="form-label">Choose date and time to schedule execution</label>
									<div style="width: 100%">
										<v-datepicker
											v-model="form.scheduledFor"
											type="datetime"
											:show-time-header="true"
											:minute-step="10"
											:show-second="false"
											style="width: 100%"
											:use12h="true"
											></v-datepicker>
									</div>
								</div>
							</div>
						</div>

					</form>
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-dismiss="modal"
						ref="closeModalButton"
						@click="close"
						>
						Close
					</button>
					<button
						type="button"
						class="btn btn-primary"
						@click="scheduleApplyCreditSystem"
						:disabled="processing || errorFetchingPrice"
						>
						{{ processing ? 'Processing...' : 'Proceed' }}
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#applyCreditLineLater"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import moment from 'moment'
import ModalMixin from '@/mixins/modal'
export default {
  props: {
    show: Boolean,
    entity: Object
  },
  mixins: [ModalMixin],
  data () {
    return {
      form: {
        narration: '',
        scheduledFor: moment().add(1, 'month').startOf('month').add(1, 'minute').toDate()
      },
      processingExecution: false,
      processing: false,
      errorMessage: '',
      errorFetchingPrice: false
    }
  },
  created () {
  },
  beforeDestroy () {
  },
  methods: {
    async scheduleApplyCreditSystem () {
      if (this.processingExecution) return
      this.processingExecution = true
      try {
        await this.axios.post(
              `/v1/credit-systems/${this.entity.id}/executions`,
              {
                scheduled: true,
                scheduled_for: this.form.scheduledFor.toISOString(),
                amount: this.entity.amount,
                narration: this.form.narration
              }
        )
        this.processingExecution = true

        await this.$swal({
          icon: 'success',
          title: 'Scheduled successfully',
          text:
              'Credit System has been scheduled to apply to the eligible staff',
          showCloseButton: true
        })
        this.close()
      } catch (e) {
        this.processingExecution = false
        this.$swal({
          icon: 'error',
          title: 'Failed to apply credit',
          text:
              e.response && e.response.data && e.response.data.message
                ? e.response.data.message
                : e.toString(),
          showCloseButton: true
        })
      }
    },
    close (e) {
      const target = (e && e.target) || this.$refs.closeModalButton
      target.click()
      this.$emit('close')
    }
  }
}
</script>
